import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';
// import fetchMethodRequest from '../../../config/service';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useForm, Controller } from 'react-hook-form';
import DoCheckboxField from '../../Form/Fields/DoCheckboxField';
const grid = 8;
import DoInputField from '../../Form/Fields/DoInputField';

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? 'grey' : 'lightgrey',
    border: '1px solid white',
    borderRadius: 10,
    // styles we need to apply on draggables
    ...draggableStyle,
    display: 'flex',
    justifyContent: 'space-between'
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'white',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    padding: grid,
    overflow: 'auto',
    border: '1px solid black',
    borderRadius: 10,
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
});
let id2List = {
    droppable: 'selectTableFields',
    droppable2: 'notSelectedTableFields'
};
const ShowHideColumnsModal = (props) => {    
    const [tableFields,setTableFields] = useState(props.tableFields);
    const [changedTableFields,setChangedTableFields] = useState('');
    const [selectTableFields,setSelectTableFields] = useState([]);
    const [notSelectedTableFields,setNotSelectedTableFields] = useState([]);

    let type = 'Width';

    useEffect(() => {
        setTableFields(props.tableFields);
        getScreenFieldsData()
    },[]);
    const {
        handleSubmit,
        register,
        reset,
        setValue,        
        control,
      } = useForm();
    

    const orderChange = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };
    const getList = id => this.state[id2List[id]];
    const onDragEnd = async (result) => {
        const { source, destination } = result;
        if (!result.destination) {
            return;
        }
        const tableFieldsT = orderChange(tableFields, result.source.index, result.destination.index);

        setTableFields(tableFieldsT);
        setChangedTableFields(tableFieldsT);
    };
    const getScreenFieldsData = async () => {
        let columns = props.tableFields;
        let tempTableFields = [];
        if (columns) {
            tempTableFields = columns;
        }
        tempTableFields.forEach(row => {
            setValue(row.field, row.show);
            setValue(row.field + 'Width', getWidth(row))
        });
        setTableFields(tempTableFields);
        setChangedTableFields(tempTableFields);
    }

    const getWidth = (row) => {
        if (row.width) {
            const widthValue = parseInt(row.width);
            return widthValue;
        }
    }

    // Onchange checkbox
    const onChange = async (event, type) => {
        let viewType = props.viewType;
        if (event && event.target.name && tableFields.length > 0) {
            const updatedFields = tableFields.map(col => {
                if (col.field === event.target.name) {
                    if (viewType === 'list') {
                        return { ...col, show: !col.show, mobile: col.mobile };
                    } else if (viewType === 'grid') {
                        return { ...col, mobile: !col.mobile, show: col.show };
                    }
                } else if (type === 'Width' && col.field + type === event.target.name) {
                    return { ...col, width: `${event?.target?.value ? event.target.value : 100}px` };
                }
                return col;
            });
            setTableFields(updatedFields);
        }
    }

    // On Confirm
    const submit = async (values) => {
        let obj = {}
        for (let tableField of tableFields) {
            obj[tableField.field] = tableField.show
            obj[tableField.field + type] = tableField.width
        }
        await props.closeShowHideColumnsModal('confirm', obj, changedTableFields)
    }


        const { t,  viewType } = props;
        return (
            <div>
                <Modal isOpen={props.isOpenShowHideColumnsModal}
                    className={`modal-dialog-centered modal-dialog--primary modal-dialog--header `}>
                    <ModalHeader className="modal__header">
                        <button className="lnr lnr-cross modal__close-btn" type="button"
                            onClick={() => props.closeShowHideColumnsModal('close', null)} />
                        <p className="bold-text  modal__title"> {t('Select Fields To Show and Reorder')} </p>
                    </ModalHeader>
                    <ModalBody className='deleteModalBody'>
                        <form onSubmit={handleSubmit(submit)} >
                            <div className='row mx-0 justify-content-center mb-3'>
                                <DragDropContext onDragEnd={onDragEnd} >
                                    <div className='col-12 col-lg-6 col-md-10 col-sm-12'>
                                        <Droppable droppableId="droppable" direction="vertical" >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                    {...provided.droppableProps}
                                                >
                                                    {tableFields && tableFields.map((item, index) => {
                                                        if (item && item.displayInSettings) {
                                                            return <div className='col-sm-12 px-0 pb-2' key={index}>
                                                                <Draggable key={item.field} draggableId={item.field} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={getItemStyle(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style
                                                                            )}
                                                                        >
                                                                            <Controller
                                                                                name={item.field}
                                                                                control={control}
                                                                                render={({ field, fieldState }) => (
                                                                                    <DoCheckboxField
                                                                                        input={field}
                                                                                        name={field.name}
                                                                                        field={field}
                                                                                        label={item.label}
                                                                                        onChangeFn={onChange}
                                                                                        fieldState={fieldState}                                                                                        
                                                                                        placeholder="Password"
                                                                                        multiple={false}
                                                                                    />)}
                                                                            />
                                                                            <div style={{ paddingRight: '10px' }}>
                                                                                <Controller
                                                                                    name={item.field + type}
                                                                                    control={control}
                                                                                    render={({ field, fieldState }) => (
                                                                                        <DoInputField
                                                                                            input={field}
                                                                                            name={field.name}
                                                                                            field={field}
                                                                                            type={'number'}
                                                                                            onChange={e => onChange(e, type)}
                                                                                            fieldState={fieldState}
                                                                                            placeholder='width'
                                                                                            style={{ width: '90px', height: '30px' }}
                                                                                        />)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                </Draggable>
                                                            </div>
                                                        }
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>

                                </DragDropContext>

                            </div>
                            <div className='col-sm-12 text-center'>
                                <Button
                                    color="primary"
                                    outline
                                    type="button"
                                    onClick={() => props.closeShowHideColumnsModal('close', null)}
                                    className='deleteModalBtn marginRight'
                                >
                                    {t('Cancel')}
                                </Button>
                                <Button
                                    color='primary'
                                    outline
                                    type="submit"
                                    className='deleteModalBtn'
                                >
                                    {t('Confirm')}
                                </Button>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
            </div>
        );
}
export default withTranslation('common')(ShowHideColumnsModal);