import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dateFormats from '../UI/FormatDate/formatDate';
import Loader from '../App/Loader';
import config from '../../config/config';
import fetchMethodRequest from '../../config/service';
import { Link } from 'react-router-dom';
import CountDisplay from './components/CountDisplay';

const Dashboard = () => {
  const { t } = useTranslation('common');

  const [dashboardData, setDashboardData] = useState({});
  const [recentMatchedProperties, setRecentMatchedProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reminders, setReminders] = useState([]);

  useEffect(() => {
    getCountData();
    matchedProperties();
    getReminders();
  }, []);

  let user = JSON.parse(localStorage.getItem('loginCredentials'))

  const getCountData = () => {
    fetchMethodRequest('GET', `dashboard/getAgentCounts?email=${user.email}`)
      .then(async (response) => {
        if (response) {
          setDashboardData(response);
        }
      }).catch((err) => {
        return err;
      });
  }

  const matchedProperties = () => {
    fetchMethodRequest('GET', `recentMatchedProperties`)
      .then(async (response) => {
        if (response && response.recentmatchedproperties && response.recentmatchedproperties.length > 0) {
          response.recentmatchedproperties.forEach((item) => {
            item.addedDate = dateFormats.formatDate(item.addedDate, config.dateDayMonthFormat);
          })
          setRecentMatchedProperties(response.recentmatchedproperties);
        }
      }).catch((err) => {
        return err;
      });
  }

  const getReminders = () => {
    fetchMethodRequest('GET', `reminders`)
      .then(async (response) => {
        if (response && response.reminders && response.reminders.length > 0) {
          setReminders(response.reminders);
        }
      }).catch((err) => {
        return err;
      });
  }

  const dateAndTimeFormat = (timestamp) => {
    if (timestamp) {
      const date = new Date(timestamp);
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, '0');
      let period = 'AM';

      if (hours >= 12) {
        period = 'PM';
        // Convert 12-hour format to 12 AM/PM format
        if (hours > 12) {
          hours -= 12;
        }
      }
      // Format the time as "hh:mm AM/PM"
      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
      let newDate = dateFormats.formatDate(timestamp, config.dateDayMonthFormat)
      return <span title={formattedTime}>
        {newDate + ' ' + formattedTime}
      </span>;
    }
  }


  return (
    <Container className="dashboard ml-5" style={{ width: '98%' }}>
      <Loader loader={isLoading} />

      <Row>
        <Col md={12}>
          <h3 className="page-title mb-1 fw-bold">{t('DashBoard')}</h3>
        </Col>
      </Row>

      {/* Counts */}
      <CountDisplay dashboardData={dashboardData} />

      <div>
        <Row>
          <Col lg={6} xl={6} >
            <Card className="dashBoardCardWithScroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">{'Recent Matched Properties'}</h5>
                </div>
                <Table className="table--bordered table--head-accent table-fixed" responsive hover>
                  <thead>
                    <tr>
                      <th style={{ width: 7 + '%' }}>{'S.No'}</th>
                      <th style={{ width: 23 + '%' }}>{'Client Name'}</th>
                      <th style={{ width: 37 + '%' }}>{'Search Name'}</th>
                      <th style={{ width: 15 + '%' }}>{'Property Count'}</th>
                      <th style={{ width: 18 + '%', paddingLeft: '50px' }}>{'Date'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recentMatchedProperties?.length > 0 ? (
                      <>
                        {recentMatchedProperties.slice(0, 5).map((item, i) => {
                          let count = item?.addedProperties?.length;
                          let propertyIds = item.addedProperties.map(item => item._id);

                          return (
                            <tr key={i}>
                              <td style={{ width: '5%', textAlign: 'center' }}>{i + 1}</td>
                              <td style={{ width: '25%', paddingLeft: '30px' }}>
                                {item.clientName}
                              </td>
                              <td style={{ width: '36%', paddingLeft: '10px' }} title={item.searchName}>{item.searchName}</td>
                              <td style={{ width: '15%', textAlign: 'center' }}>
                                <Link
                                  to="/showMatchedProperties"
                                  state={{ showPropertiesList: propertyIds }}
                                  style={{ color: '#0e4768', fontWeight: 'bold' }}
                                >
                                  {count}
                                </Link>
                              </td>
                              <td style={{ width: '18%', textAlign: 'center' }}>{item.addedDate}</td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr className="bold-text">
                        <td colSpan="5">
                          No Recent Properties
                        </td>
                      </tr>
                    )}
                  </tbody>
                  {recentMatchedProperties?.length > 0 ?
                    < div className="d-flex justify-content-end pt-2 pe-4">
                      <Link to="/RecentMatchedProperties">...More</Link>
                    </div>
                    : null}
                </Table>
              </CardBody>
            </Card>
          </Col>

          <Col lg={6} xl={6} >
            <Card className="dashBoardCardWithScroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">{'Reminders'}</h5>
                </div>
                <Table className="table--bordered table--head-accent table-fixed" responsive hover>
                  <thead>
                    <tr>
                      <th style={{ width: 7 + '%' }}>{'S.No'}</th>
                      <th style={{ width: 25 + '%' }}>{'Title'}</th>
                      <th style={{ width: 23 + '%' }}>{'Client Name'}</th>
                      <th style={{ width: 27 + '%' }}>{'Description'}</th>
                      <th style={{ width: 18 + '%', paddingLeft: '45px' }}>{'Date'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reminders?.length > 0 ? (
                      <>
                        {reminders.slice(0, 5).map((item, i) => {
                         let clientName = item.clientName?.firstName;
                          return (
                            <tr key={i}>
                              <td style={{ width: '5%', textAlign: 'center' }}>{i + 1}</td>
                              <td style={{ width: '26%', paddingLeft: '28px' }} title={item.title}>{item.title}</td>
                              <td style={{ width: '24%', paddingLeft: '20px' }} title={clientName}>{clientName}</td>
                              <td style={{ width: '25%' }} title={item.description}>{item.description}</td>
                              <td style={{ width: '20%', textAlign: 'center' }}>{dateAndTimeFormat(item.date)}</td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr className="bold-text">
                        <td colSpan="5">No Data</td>
                      </tr>
                    )}
                  </tbody>
                  {reminders?.length > 0 ?
                    < div className="d-flex justify-content-end pt-2 pe-4">
                      <Link to="/reminders">...More</Link>
                    </div>
                    : null}
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Dashboard;
