import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import DoTextareaField from "../../Form/Fields/DoTextareaField";
import fetchMethodRequest from "../../../config/service";
import showToasterMessage from "../../UI/ToasterMessage/toasterMessage";

const AddNotesModal = ({ type, openAddNotesModal, closeAddNotesModal, rowData, contextTypeAction }) => {

    const modalHeader = type === "Clients" ? "Notes" : "Comments";
    let {
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
        control,
    } = useForm();

    const onClickUpdate = (data) => {
        console.log("hello", data)
        let abc={
            "notes": data.notes,
            "contextType": contextTypeAction ,
            "contextId": rowData._id
        }
        console.log(abc)
        closeAddNotesModal();

        return fetchMethodRequest("POST", "notes", abc)
            .then(async (response) => {
                let sessionexpired = localStorage.getItem('sessionexpired')
                if (sessionexpired == "true") {
                    setSessionExpiryModal(true);
                }
                if (response && response.respCode) {
                    showToasterMessage("Notes Added", 'success');
                    
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
            
    }


    return (
        <div style={{ display: 'flex' }}>
            <Modal isOpen={openAddNotesModal}
                className={` modal-dialog--primary modal-dialog--header`}
            >
                <ModalHeader className="modal__header viewModalHeader" >
                    Add {modalHeader}
                    <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeAddNotesModal} />
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit(onClickUpdate)}>
                        <div className='row'>
                            <div className='col-sm-12 pb-2'>
                                <Controller
                                    name={"notes"}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoTextareaField
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            field={field}
                                            fieldState={fieldState}
                                            label={modalHeader}
                                            errors={errors}
                                            placeholder={`Add ${modalHeader}`}
                                        />)}
                                />
                            </div>
                        </div>


                        <div className='d-flex mt-3 justify-content-end'>
                            <Button type="button" size='sm' color='primary' label='Back' className='me-3' onClick={closeAddNotesModal} />
                            <Button type='submit' size='sm' color='primary' label='Save' />
                        </div>


                    </form>
                </ModalBody>

            </Modal>

        </div >
    );

}



export default AddNotesModal;
