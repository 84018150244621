import React from 'react';
import configImages from '../../../config/configImages';

const Chat = ({ activitiesData }) => {

    const noProfileImage = configImages.defaultImg;

    return (
        <div className="container mt-3">
            <div className="row">
                <div className="col-md-12">
                    <div className="panel panel-primary">
                        <div className="panel-body">
                            <ul className="chat" style={{ listStyleType: 'none', paddingLeft: 0 }}>
                                {activitiesData?.length > 0 && activitiesData.map((message, index) => (
                                    <li
                                        key={index}
                                        className="clearfix right mb-4"
                                    >
                                        {/* <span className="chat-img pull-right">
                                            <img
                                                className="topbar__avatar-img"
                                                src={noProfileImage}
                                                alt="Profile"
                                            />
                                        </span> */}
                                        <div className="chat-body clearfix mt-1">
                                            <div className="header">
                                                <strong className="primary-font me-2">{message.createdBy}</strong>
                                                <small className="pull-right text-muted">
                                                    {message.created}
                                                </small>
                                            </div>
                                            <p>{message.notes || message.comments }</p>
                                        </div>
                                        <hr style={{ width: '95%' }} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;
