import React from "react";
import { Calendar } from 'primereact/calendar';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";


const DoDateField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;    
    const name = props.name;
    const placeholder = props.placeholder;
    const selectionMode = props.selectionMode;
    const showButtonBar = props.showButtons;
    let field = props.field;
    let timeOnly = props.timeOnly
    let formType = props.formType;

    if (formType == 'edit'){
      field.value = moment(new Date(props.field.value)).format("YYYY-MM-DDTHH:mm")
    }

    let markRequired = () => {
        return (
          <FontAwesomeIcon
            color='red'
            icon={faAsterisk}
            style={{ width: '7px',marginBottom:"5px",marginLeft:"2px" }}
          />
        );
      };

    const styles = {
        fontSize: '1rem',
        color: '#495057',
        background: '#ffffff',
        padding: '0.75rem 0.75rem',
        border: '1px solid #ced4da',
        transition: 'background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s',
        appearance: 'none',
        borderRadius: '6px',
    };

    return (
        <div className="flex flex-column ">
            <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired() }
            </div>

          {!props.fieldUse ? (
            <Calendar
                showButtonBar={showButtonBar ? true : false}
                selectionMode={selectionMode ? selectionMode : 'single'}
                inputId={field.name}
                value={field.value}
                onChange={field.onChange}
                dateFormat="dd/mm/yy"
                placeholder={placeholder}
                timeOnly={timeOnly}
                showIcon
            />
            ) : (
            <input
              type="datetime-local"
              id={field.name}
              value={field.value}
              name={name}
              placeholder="DD/MM/YYYY, HH:MM"
              onChange={field.onChange}
              style={styles}
            />
            )}

        <small className="text-danger mt-1">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
        </div>
    )

}

export default DoDateField;